exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-altanki-js": () => import("./../../../src/pages/altanki.js" /* webpackChunkName: "component---src-pages-altanki-js" */),
  "component---src-pages-balustrady-js": () => import("./../../../src/pages/balustrady.js" /* webpackChunkName: "component---src-pages-balustrady-js" */),
  "component---src-pages-drzwi-js": () => import("./../../../src/pages/drzwi.js" /* webpackChunkName: "component---src-pages-drzwi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-js": () => import("./../../../src/pages/layout.js" /* webpackChunkName: "component---src-pages-layout-js" */),
  "component---src-pages-meble-js": () => import("./../../../src/pages/meble.js" /* webpackChunkName: "component---src-pages-meble-js" */),
  "component---src-pages-ogrodzenia-js": () => import("./../../../src/pages/ogrodzenia.js" /* webpackChunkName: "component---src-pages-ogrodzenia-js" */),
  "component---src-pages-pomosty-js": () => import("./../../../src/pages/pomosty.js" /* webpackChunkName: "component---src-pages-pomosty-js" */),
  "component---src-pages-rozmaite-js": () => import("./../../../src/pages/rozmaite.js" /* webpackChunkName: "component---src-pages-rozmaite-js" */),
  "component---src-pages-schody-js": () => import("./../../../src/pages/schody.js" /* webpackChunkName: "component---src-pages-schody-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */)
}

